
/** Boost CSS Variables **/

@font-face {
  font-family: boost-font-light;
  src: url(/assets/font/OpenSans-Light.ttf);
}
@font-face {
  font-family: boost-font-regular!important;
  src: url(/assets/font/OpenSans-Regular.ttf);
}
@font-face {
  font-family: boost-font-medium;
  src: url(/assets/font/OpenSans-Semibold.ttf);
}
@font-face {
  font-family: boost-font-italic;
  src: url(/assets/font/OpenSans-Italic.ttf);
}
:root {
  --boost-font-family: boost-font-regular;
  --font-size: 16px;

  /** primary **/
  --primary-color: var(--primary-color);
  --primary-text-color: var(--primary-text-color); //rgb(130, 130, 130)

  /** secondary **/
  --color-secondary: var(--color-secondary); //rgb(79, 79, 79)

  /** tertiary **/
  --color-tertiary: #b13f8f;
  --color-grey: var(--color-grey);
  --color-warn: var(--warn-color);
  --tertiary-text-color: #00000099; //rgba(0, 0, 0, 0.66)
  --tertiary1-text-color: #00000061; //rgba(0, 0, 0, 0.38)


  /** white **/
  --color-white: var(--color-white); //rgba(0, 0, 0, 0.87)
  --color-contrast: var(--primary-color);
}

// =======================Font-Size====================

// EM value is calculated by root font size which is 16px in body.
// For ex-- 24px/16px = 1.5em

$extra-large-font: 1.625em; //---------26px;
$large-font: 1.45em; //---------20px;
$medium-font: 1.3em; //---------18px;
$root-font: 1.15em; //---------16px;
$default-font: 1em; //---------14px;
$default-medium-font: 1em; //---------13px;
$small-font: 0.86em; //---------12px;
$extra-small-font: 0.72em; //---------10px;

// =======================color====================

$darkgray: #434343;
$lightgray: #959494;
$lightyellow :var(--primary-color);
$darkyellow:#EA9A00;
$darkyBlue:#080059;

// ==================Box-Sizing=============
$border-box: border-box;
$content-box: content-box;
