/******************
TAILWIND STYLES
*******************/
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

// @import '~ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
// @import '~ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

@import '~ngx-toastr/toastr';

@import './../../dashboard-app/src/theme/main.scss';
@import './../../dashboard-app/src/styles.scss';
@import './../../manage-user-app/src/styles.scss';
@import './../../manage-content-app/src/styles.scss';
@import './../../addon-marketplace-app/src/styles.scss';

/******************
GLOBAL STYLES
*******************/
html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

input:focus {
  outline: none !important;
  box-shadow: none !important;
}

//adding style for latest update update-description class
//anchor tag
.something {
  display: block;
  a {
    color: #5b9bd1 !important;
  }
  a::hover {
    color: #337ab7 !important;
  }
}




.page-head .page-title > h1 {
  text-transform: capitalize;
}
 .mat-tab-label-active:hover {
  background-color: var(--secondary-grey);
}

// #launcher {
//   button{
//     background-color: var(--primary-color) !important;
//     background: var(--primary-color) !important;
//   }
// }

