/* You can add global styles to this file, and also import other style files */

@import "../src/theme/main.scss";












#load {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0%;
  background-color: rgba(255, 255, 255, 0.905);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
#load img {
  width: 240px;
  height: 240px;
  border-radius: 50%;
}
#load::after {
  color: #000;
  // content: "Loading Dashboard...";
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 100px;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
}

$primary: var(--primary-color);
:root {
  --primary: var(--primary-color);
  --secondary: var(--secondary-grey);
  --whiteColor: var(--color-white);
}

.activity-header {
  display: flex;
  justify-content: space-between;
  max-width: 100%;
  margin: auto;
  align-items: center;
  position: relative;
  margin-top: 60px;

  @media all and (max-width: 781px) {
    justify-content: center;
    flex-direction: column;
    gap: 20px;
  }
}
.section-button {
  display: inline-block;
  color: var(--primary-text-color);
  font-size: 20px !important;
  margin: 20px 0 !important;
  background-color: var(--color-grey);
  border-radius: 4px;
  padding: 8px 12px;
  ;
}
.section-button-inc {
  margin: 0px;
}

.border-button,
.border-button:focus {
  background: var(--color-white);
  border: 1px solid var(--tertiary-grey);
  border-radius: 4px;
  height: 43px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: var(--primary-text-color);
  outline: none;
  @include padding(8px, 60px, 8px, 60px);
  @include margin(75px, auto, 75px, auto);
  display: block;
}

.border-button:hover {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}

.btn-yellow {
  background: var(--primary-color);
  border-radius: 4px;
  height: 40px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  text-transform: uppercase;
  color: var(--color-white);
  outline: none;
  @include padding(8px, 30px, 8px, 30px);
  @include margin(32px, auto, 75px, auto);
  display: block;
  border: none;
}

.btn-yellow:hover {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  transition: box-shadow 0.3s cubic-bezier(0.23, 1, 0.32, 1);
}

.flex-space-betweeen {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

// Tooltip CSS

.tooltip {
  position: relative;
  display: initial;
  opacity: 1;
  z-index: auto;
  bottom: 2px;
  margin-left: 5px;
}
.tooltip img {
  position: relative;
  z-index: 2;
}
.tooltip .tooltiptext {
  background: var(--primary-text-color);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  padding: 13px 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 17px;
  color: var(--color-white);
  text-align: left;
  width: 250px;
  visibility: hidden;
  position: absolute;
  z-index: 9;
  bottom: 25px;
  left: -17px;
}
.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 10%;
  margin-left: -5px;
  border-width: 9px;
  border-style: solid;
  border-color: var(--primary-text-color) transparent transparent
    transparent;
}
.tooltip:hover {
  cursor: pointer;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.video-modal {
  width: 80%;
  max-width: 80%;
  margin: auto;
  margin-top: 1%;
  margin-bottom: 4%;
}

@media all and (max-width: 650px) {
  .video-modal {
    width: 90%;
    max-width: 90%;
  }
}



