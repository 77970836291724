@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;1,100;1,300;1,400;1,500&display=swap');

// @import '~/ngx-owl-carousel-o/lib/styles/scss/owl.carousel';
// @import '~/ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';

@import 'node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.carousel.scss';
@import 'node_modules/ngx-owl-carousel-o/lib/styles/scss/owl.theme.default';
// @import '~ngx-toastr/toastr.css';

// #toast-container > div {
//   opacity: 1;
//   background-color: var(--primary-color);
//   color: var(--whiteColor);
// }

body {
  font-family: 'Open Sans', sans-serif !important;
}

$primary: var(--primary-color);

:root {
  --primary: var(--primary-color);
  --secondary: var(--secondary-grey);
  --whiteColor: var(--color-white);
}

.hide {
  display: none;
}
.show {
  display: block;
}

.snackbar-color {
  background-color: var(--primary-color) !important;
  color: var(--color-white) !important;
  margin-bottom: 70px !important;
  z-index: 12;
}

#load {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0%;
  background-color: var(--color-white);
  opacity: 0.9;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
#load img {
  width: 240px;
  height: 240px;
  border-radius: 50%;
}
#load::after {
  color: #000;
  content: 'Loading marketplace...';
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 100px;
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
}

* {
  transition: all 0.1s ease-in-out;
}

img:focus {
  outline: none;
}
button,
.section-button {
  cursor: pointer;
}
button {
  font-weight: 600;
}

.container {
  max-width: 1140px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

/*banner carousel*/

.banner-carousel .item.active {
  outline: none;
}
.banner-carousel .item.active img {
  outline: none;
}
.carousel-indicators {
  bottom: -50px;
}
.owl-theme .owl-dots {
  display: flex;
  justify-content: center;
}
.owl-theme .owl-dots .owl-dot span {
  width: 6px;
  height: 6px;
  background: var(--secondary);
  border-radius: 10px;
}
.owl-theme .owl-dots .owl-dot.active span {
  width: 12px;
  background: $primary;
  border-radius: 10px;
}

.carousel-control {
  display: none !important;
}

.carousel:focus {
  outline: none;
  border: none;
}
/*banner carousel*/

/*section heading*/

.home-section-heading {
  font-family: Open Sans;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  line-height: 120% !important;
  color: var(--primary-text-color) !important;
  margin-bottom: 8px !important;
}
.home-section-sub-heading {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--primary-text-color);
  margin-bottom: 23px;
}

/*section heading*/

.flex-custom {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.flex-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flex-justify-end {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/*button*/

.btn-border,
.btn-border:focus {
  border: 1.21429px solid $primary;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 13.3571px;
  line-height: 15px;
  text-align: center;
  color: $primary;
  padding: 8px 17px;
  background-color: var(--whiteColor);
  outline: none;
  cursor: pointer;
}
.btn-yellow,
.btn-yellow:focus {
  background: $primary;
  border-radius: 4px;
  height: 36px;
  padding: 9px 26px;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */
  justify-content: center;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: capitalize;
  color: var(--whiteColor);
  border: none;
  outline: none;
  cursor: pointer;
  transition: none !important;
  margin: 0;
}

.btn-border-rounded,
.btn-border-rounded:focus {
  background: var(--whiteColor);
  border: 1px solid var(--secondary);
  border-radius: 4px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: $primary;
  padding: 8px 17px;
  text-transform: uppercase;
  background-color: var(--whiteColor);
  outline: none;
  cursor: pointer;
}
.btn-border-rounded,
.btn-border-rounded:focus {
  background: var(--color-white);
  border: 1px solid var(--secondary-grey);
  border-radius: 4px;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  color: var(--primary-color);
  padding: 8px 17px;
  text-transform: uppercase;
  background-color: var(--color-white);
  outline: none;
  cursor: pointer;
}

.btn-grey-rounded {
  border: 1px solid var(--tertiary-grey);
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--tertiary-grey);
  background-color: var(--whiteColor);
  padding: 8px 17px;
  cursor: pointer;
}

.btn-remove {
  background: #d04b43 !important;
  border-radius: 4px;
  padding: 9px 26px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: var(--whiteColor) !important;
  outline: none;
  border: none;
  cursor: pointer;
}
.btn-100,
.btn-100:focus {
  width: 100%;
}

button:focus {
  outline: none;
}

.modal-backicon {
  cursor: pointer;
}
.back-arrow {
  cursor: pointer;
}
/*youtube owl carousel*/

.youtube-owl .owl-prev {
  width: 37px;
  height: 130px;
  position: absolute;
  top: 0px;
  margin-left: 0px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px solid black;
  bottom: 0;
}

.youtube-owl .owl-next {
  width: 37px;
  height: 130px;
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px solid black;
}
.youtube-owl .owl-theme .owl-nav [class*='owl-'] {
  margin: 0;
  padding: 0;
  background: $primary;
  display: flex;
  border-radius: 4.36488px;
}

.youtube-owl .owl-theme .owl-nav .disabled {
  background: var(--secondary);
  opacity: 1;
}

.youtube-owl .owl-theme .owl-nav .disabled .owl-left-arrow {
  background: var(--secondary);
}
.youtube-owl .owl-theme .owl-nav .disabled .owl-right-arrow {
  background: var(--secondary);
}

.youtube-owl .owl-carousel.owl-drag .owl-item {
  // max-width: 300px;
  max-width: 240px;
}

.youtube-owl .owl-theme {
  padding-right: 35px;
}

/*youtube owl carousel ends*/

/*banner carousel owl*/

.banner-owl .owl-prev {
  width: 37px;
  height: 130px;
  position: absolute;
  top: 0px;
  margin-left: 0px;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px solid black;
  bottom: 0;
  left: -17px;
}

.banner-owl .owl-next {
  width: 37px;
  height: 130px;
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px solid black;
  right: -17px;
}
.banner-owl .owl-theme .owl-nav [class*='owl-'] {
  margin: 0;
  padding: 0;
  background: var(--primary-color);
  display: flex;
  border-radius: 4.36488px;
  top: 65px;
}

.banner-owl .owl-theme .owl-nav .owl-prev.disabled {
  background: var(--secondary-grey);
  opacity: 1;
  left: -17px;
}
.banner-owl .owl-theme .owl-nav .owl-next.disabled {
  background: var(--secondary-grey);
  opacity: 1;
  right: -17px;
}

.banner-owl .owl-theme .owl-nav .disabled .owl-left-arrow {
  background: var(--secondary-grey);
}
.banner-owl .owl-theme .owl-nav .disabled .owl-right-arrow {
  background: var(--secondary-grey);
}
/*banner carousle owl ends*/

/*plan carousel owl*/

.plan-owl .owl-prev {
  left: -17px;
}

.plan-owl .owl-next {
  right: -17px;
}

.plan-owl .owl-theme .owl-nav [class*='owl-'] {
  margin: 0;
  padding: 0;
  background: var(--primary-color);
  // mix-blend-mode: normal;
  // border: 1px solid var(--tertiary-grey);
  border-radius: 4px;
  top: 40%;
  width: 36px;
  height: 36px;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
.addon-owl .owl-theme .owl-nav [class*='owl-'] {
  top: 103px;
}
.partner-owl .owl-theme .owl-nav [class*='owl-'] {
  top: 85px;
}
.plan-owl .owl-theme .owl-nav .owl-prev.disabled {
  background: var(--secondary-grey);
  opacity: 1;
  left: -17px;
}
// .plan-owl .owl-theme .owl-nav .owl-prev:hover, .plan-owl .owl-theme .owl-nav .owl-next:hover {
//   background-color: var(--primary-color);
//   border: 1px solid var(--primary-color);
// }
// .plan-owl .owl-theme .owl-nav .left-arrow:hover, .plan-owl .owl-theme .owl-nav .right-arrow:hover{
//    background-color: var(--primary-color);
// }
.plan-owl .owl-theme .owl-nav .owl-next.disabled {
  background: var(--secondary-grey);
  opacity: 1;
  right: -17px;
}
.plan-owl .owl-theme .owl-nav .left-arrow {
  // background: var(--color-grey);
  width: 11px;
  height: 13px;
  display: flex;
}
.plan-owl .owl-theme .owl-nav .right-arrow {
  // background: var(--color-grey);
  width: 11px;
  height: 13px;
  display: flex;
}
/*plan carousel owl ends*/

/*partner offering owl carousel */
.partner-carousel .owl-theme .owl-dots .owl-dot.active span {
  width: 6px;
  height: 6px;
  background: var(--primary-text-color);
  border: 1px solid var(--whiteColor);
  opacity: 1;
}

.partner-carousel .owl-theme .owl-dots .owl-dot span {
  background: var(--primary-text-color);
  opacity: 0.4;
  border: 1px solid var(--whiteColor);
}

/*partner offering owl carousel ends*/

/* Addons tabs*/
.addons-tab-wrapper .nav-tabs .nav-item {
  margin-right: 10px;
}
.addons-tab-wrapper .nav-tabs .nav-item .nav-link {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  color: var(--tertiary-grey);
  border: 1px solid var(--secondary-grey);
  border-bottom: var(--whiteColor);
  padding: 8px 0px;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  min-width: 186px;
}

.addons-tab-wrapper .nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: 1px solid var(--tertiary-grey) !important;
  border-bottom: var(--whiteColor) !important;
  display: block;
  font-weight: 500 !important;
}
.addons-tab-wrapper .nav-tabs {
  border-bottom: 1px solid var(--tertiary-grey);
}
/* Addons tabs ends*/

/*Addons detail modal*/
.addons-detail-modal {
  max-width: 580px !important;
  min-width: 580px !important;
}
.addons-detail-modal .modal-content {
  background: var(--whiteColor);
  border: 1px solid var(--tertiary-grey);
  box-sizing: border-box;
  border-radius: 20px;
  overflow: hidden;
}

.addons-detail-header {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  height: 60px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: var(--tertiary-grey);
  justify-content: space-between;
  box-shadow: (0px 1.78571px 3.57143px rgba(0, 0, 0, 0.149));
  padding: 0 22px 0 35px;
}

.addons-detail-content {
  padding: 35px 40px;
}

.addon-logo {
  width: 117px;
  height: 117px;
  box-shadow: (0px 4px 4px rgba(0, 0, 0, 0.2));
  border-radius: 11.118px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.addon-logo img {
  max-width: 100%;
  height: auto;
}
.addon-header-txt {
  margin-left: 30px;
  flex: 1;
}
.addon-header-txt h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 31px;
  color: var(--tertiary-grey);
  margin-bottom: 0;
}
.addon-header-txt p {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: $primary;
  margin-bottom: 0;
  margin-top: 11px;
}
.addon-header-txt button {
  margin-top: 10px;
}
.addon-para {
  margin-top: 20px;
  height: 260px;
  overflow-x: auto;
  padding-right: 10px;
}
.addon-para h3 {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: var(--primary-text-color) !important;
}
.addon-para p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: var(--tertiary-grey);
  margin-top: 0px;
  margin-bottom: 0;
}

.addon-para::-webkit-scrollbar {
  width: 4px;
}

.addon-para::-webkit-scrollbar-track {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

.addon-para::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: var(--tertiary-grey);
}
/*Addonse detail modal ends*/

/*recommendation modal*/

.recommended-modal-content {
  display: inline-flex;
  gap: 15px;
  flex-wrap: wrap;

  .recommended-card img {
    margin: auto;
    display: block;
  }
  .recommended-card {
    border: 2px solid transparent;
  }
  .recommended-card.active {
    border: 2px solid var(--primary-color);
    box-shadow: 0px 4.44721px 4.44721px rgba(0, 0, 0, 0.04);
  }
}

.addons-detail-modal .recommended-wrapper {
  background-color: var(--color-grey);
  padding: 28px 30px;
  border-radius: 0 0 20px 20px;
  max-height: 547px;
  overflow-y: auto;
}
.recommended-wrapper::-webkit-scrollbar {
  width: 4px;
}

.recommended-wrapper::-webkit-scrollbar-track {
  border-radius: 20px;
  -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
}

.recommended-wrapper::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: var(--tertiary-grey);
}
.recommended-card {
  background: var(--whiteColor);
  mix-blend-mode: normal;
  box-shadow: 0px 4.44721px 4.44721px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  padding: 15px;
  width: 160px;
  position: relative;
  height: 260px;
  cursor: pointer;
}

.recommended-card img {
  width: 120px;
  height: 120px;
  margin: auto;
}
.recommended-card p {
  margin-bottom: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: var(--primary-text-color);
  margin-top: 10px;
}
.recommended-modal-content .price-text-bx {
  height: 50px;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  padding: 0 15px;
}
.recommended-modal-content .price-text {
  font-style: normal;
  font-weight: 500;
  font-size: 12.2298px;
  line-height: 17px;
  color: var(--primary-color);
  display: block;
}
.line-through-text {
  text-decoration: line-through;
  color: var(--tertiary-grey);
  font-size: 11px;
  line-height: 15px;
}
.button-bx {
  background-color: var(--color-grey);
  border-radius: 0 0 20px 20px;
  padding: 0px 0;
}
.button-bx button {
  margin: 20px auto auto;
}
/*recommendation modal ends*/

.iframeWrapper {
  padding: 0;
  max-height: auto !important;
  background-color: var(--color-grey);
  border-radius: 0 0 20px 20px;
  overflow-y: auto;
}

/*compare modal*/
.compare-modal {
  max-width: 1000px;
}
.compare-modal .modal-content {
  background: var(--whiteColor);
  border: 1px solid var(--tertiary-grey);
  box-sizing: border-box;
  border-radius: 20px;
}

.compare-modal-header {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  height: 60px;
  display: flex;
  align-items: center;
  text-align: center;
  background: $primary;
  // border-radius: 15px 15px 0 0;
  color: var(--whiteColor);
  justify-content: space-between;
  box-shadow: 0px 1.78571px 3.57143px rgba(0, 0, 0, 0.149);
  padding: 0 22px 0 35px;
}
.compare-modal-content {
  display: inline-flex;
  gap: 18px;
  flex-wrap: nowrap;
  padding-right: 30px;
}

.compare-modal-wrapper {
  border-radius: 4px;
  overflow: auto;
}
.compare-wrapper {
  background-color: var(--whiteColor);
  padding: 28px 30px;
  padding-bottom: 15px;
  border-radius: 0 0 20px 20px;
  overflow-y: auto;
}

.compare-wrapper::-webkit-scrollbar {
  height: 4px;
}

.compare-wrapper::-webkit-scrollbar-track {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

.compare-wrapper::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: var(--tertiary-grey);
}
.compare-logo {
  width: 65px;
  height: 65px;
  box-shadow: (0px 4px 4px rgba(0, 0, 0, 0.2));
  border-radius: 11.118px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.compare-logo img {
  max-width: 100%;
  height: auto;
}
.compare-header-txt {
  margin-left: 20px;
  flex: 1;
}
.compare-header-txt h2 {
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 26px;
  color: #000000;
  margin-bottom: 0;
}
.compare-header-txt p {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 148.1%;
  color: var(--tertiary-grey);
  margin-bottom: 0;
  margin-top: 2px;
}
.ideal-for {
  font-weight: 500;
  font-size: 10px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  color: $primary;
  margin-top: 4px;
}
.compare-card {
  background: var(--whiteColor);
  border: 1px solid var(--secondary-grey);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 15px;
  width: 300px;
  position: relative;
}
.compare-card .flex-custom {
  padding: 15px 15px 20px;
}
.compare-table {
  height: 320px;
  overflow-x: auto;
  position: relative;
  padding-bottom: 45px;
}
.compare-card .btn-bx {
  position: absolute;
  background-color: var(--whiteColor);
  width: 100%;
  height: 40px;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 10px;
  margin-bottom: 5px;

  // border-radius: 0 0 10px 20px;
  // margin-bottom: 5px;
  border-radius: 0 0 10px 20px;
}
.compare-card .btn-bx button {
  height: 30px;
}
.compare-table table {
  width: 100%;
}
.compare-table table th {
  width: 100%;
  background: var(--secondary-grey);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #4e4e4e;
  padding: 4px 10px;
}
.compare-table table td {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #4e4e4e;
  padding: 8px 10px;
}
.compare-table table tr:nth-of-type(odd) {
  background: var(--color-grey);
}

.compare-table::-webkit-scrollbar {
  width: 4px;
}

.compare-table::-webkit-scrollbar-track {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

.compare-table::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: var(--tertiary-grey);
}

.compare-para {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 130%;
  color: var(--tertiary-grey);
  text-align: left;
  padding: 20px;
  margin-bottom: 0;
  padding-bottom: 0;
}
/*compare modal ends*/

/*Request call back modal start*/

.call-back-modal {
  max-width: 538px;
}
.call-back-modal .modal-content {
  background: var(--whiteColor);
  border: 1px solid var(--tertiary-grey);
  box-sizing: border-box;
  border-radius: 20px;
}
.call-back-modal .addons-detail-header {
  color: var(--primary-text-color);
}
.callback-modal-wrapper {
  background-color: var(--color-grey);
  padding: 28px 30px;
  border-radius: 0 0 20px 20px;
}
.callback-modal-content form {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.callback-modal-content form input {
  background: var(--whiteColor);
  border: 1px solid var(--secondary);
  box-sizing: border-box;
  border-radius: 6px;
  height: 40px;
  font-size: 20px;
  line-height: 27px;
  color: var(--primary-text-color);
  padding: 0 10px;
  width: 100%;
}
.callback-modal-content form input:focus {
  outline: none;
}
.callback-modal-content form input.disabled {
  background: var(--color-grey);
  border: 1px solid var(--secondary);
}
.callback-modal-content form label {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  display: block;
  margin-bottom: 3px;
}
.callback-modal-content form div {
  width: 46%;
  position: relative;
}
.callback-modal-content form .left-control {
  margin-right: 10px;
}
.callback-modal-content form .right-control input {
  padding-left: 48px;
}
.prefix-digit {
  position: absolute;
  left: 10px;
  top: 27px;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 27px;
  color: var(--tertiary-grey);
}
.call-back-modal .modal-content .button-bx button {
  font-weight: bold;
  margin: 40px 0 20px 0;
}
.call-back-modal .modal-content .button-bx {
  display: flex;
  justify-content: flex-end;
}

.request-sent-wrapper {
  background: var(--color-grey);
  border-radius: 20px;
  text-align: center;
  position: relative;
  padding: 62px 0;
}
.request-sent-wrapper h4 {
  font-style: normal;
  font-weight: 500;
  font-size: 26.2295px;
  line-height: 36px;
  text-align: center;
  color: var(--primary-text-color);
}
.request-sent-wrapper p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: var(--tertiary-grey);
}
.request-sent-wrapper .modal-close {
  position: absolute;
  right: 20px;
  top: 20px;
}
.modal-close {
  cursor: pointer;
}
/*Request call back modal ends*/

// slide modal

.slide-modal {
  width: 428px;
}
.slide-modal .addons-detail-header {
  height: 100px;
}

.slide-modal .recommended-wrapper {
  background-color: var(--color-grey);
  padding: 28px 30px;
  border-radius: 0;
  height: 100vh;
  overflow-x: auto;
  padding-bottom: 20%;
}

.slide-modal .recommended-wrapper::-webkit-scrollbar {
  width: 4px;
}

.slide-modal .add-cart-wrapper {
  background: var(--whiteColor);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  max-width: 442px;
  margin-bottom: 34px;
  cursor: pointer;
}

// slide modal close

/*Renewal History Modal start*/

.renewal-history-modal {
  width: 428px;
}
.renewal-history-modal .addons-detail-header {
  height: 100px;
}

.renewal-history-modal .recommended-wrapper {
  background-color: var(--color-grey);
  padding: 28px 30px;
  border-radius: 0;
  height: 100vh;
  overflow-x: auto;
  padding-bottom: 20%;
}

.renewal-history-modal .recommended-wrapper::-webkit-scrollbar {
  width: 4px;
}

.renewal-history-modal .recommended-wrapper::-webkit-scrollbar-track {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

.renewal-history-modal .recommended-wrapper::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: var(--tertiary-grey);
}

.renewal-history-modal .add-cart-wrapper {
  background: var(--whiteColor);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  max-width: 442px;
  margin-bottom: 34px;
  cursor: pointer;
}
.add-cart-header {
  border-bottom: 0.892857px solid var(--secondary-grey);
  padding: 10px 20px;
}
.add-cart-header h4 {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 12px !important;
  line-height: 16px !important;
  color: var(--primary-text-color) !important;
  margin-bottom: 0 !important;
  text-transform: uppercase !important;
}
.add-cart-content {
  padding: 20px;
}
.add-cart-icon {
  width: 64px;
  height: 64px;
}

.add-text-wrapper {
  margin-left: 20px;
  position: relative;
  flex: 1;
}

.cart-close {
  position: absolute;
  right: 0px;
  top: 0;
  bottom: 0;
  margin: auto;
  width: 13px;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.cart-close img {
  max-width: 100%;
  height: auto;
}
.add-cart-content {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.add-cart-content img {
  max-width: 100%;
}
.add-text-wrapper h4 {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 20px !important;
  color: var(--primary-text-color) !important;
  margin-bottom: 6px !important;
}

.addon-price {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.addon-price p {
  font-weight: normal !important;
  font-size: 10px !important;
  line-height: 14px !important;
  color: var(--tertiary-grey) !important;
  margin-bottom: 0 !important;
}
.addon-price p span {
  color: $primary !important;
  font-weight: 500 !important;
}
.addon-price b {
  width: 38px;
  height: 15px;
  background: #f8e71c;
  margin-left: 4px;
  border-radius: 28.0855px;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  color: #2a2a2a;
  text-align: center;
}

.view-addons {
  font-style: normal;
  font-weight: normal;
  font-size: 10.7143px;
  line-height: 13px;
  color: var(--tertiary-grey);
  display: inline-block;
}

.add-cart-header h6 {
  font-size: 12px !important;
  line-height: 16px !important;
  text-align: right !important;
  color: var(--primary-text-color) !important;
  margin-bottom: 0 !important;
}
.renewal-modal-content .add-cart-wrapper .add-cart-content {
  border-bottom: 1px dashed var(--secondary-grey);
}

.add-cart-date {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: var(--tertiary-grey);
  padding: 15px;
}
.pad-wrapper {
  padding: 0 15px;
}
.renewal-modal-content .add-cart-wrapper .add-cart-content {
  padding: 20px 0;
  padding-bottom: 10px;
}
.renewal-history-modal {
  position: fixed !important;
  margin: auto;
  // height: 100%;
  right: 0 !important;
  -webkit-transition: opacity 0.3s linear, right 0.4s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.4s ease-out;
  -o-transition: opacity 0.3s linear, right 0.4s ease-out;
  transition: opacity 0.3s linear, right 0.4s ease-out;
}
.mat-dialog-container {
  padding: 0 !important;
}
.modal.fade.show .renewal-history-modal {
  right: 0 !important;
  -webkit-transition: opacity 0.3s linear, right 0.4s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.4s ease-out;
  -o-transition: opacity 0.3s linear, right 0.4s ease-out;
  transition: opacity 0.3s linear, right 0.4s ease-out;
  top: -2px;
}

.modal.fade .renewal-history-modal {
  right: -320px;
  -webkit-transition: opacity 0.3s linear, right 0.4s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.4s ease-out;
  -o-transition: opacity 0.3s linear, right 0.4s ease-out;
  transition: opacity 0.3s linear, right 0.4s ease-out;
}

.modal.fade.show .renewal-history-modal {
  right: 0;
}

.renewal-history-modal .modal-content {
  border-radius: 0;
  border: none;
}
/*Renewal History Modal ends*/

/*Order History Modal Start*/
.order-history-modal .addons-detail-header {
  justify-content: space-between;
  padding: 0 35px 0 35px;
}
.order-history-modal .addons-detail-header p {
  margin-bottom: 0;
  margin-left: 10px;
  text-align: left;
  width: 200px;
}
.order-history-modal .addons-detail-header p span {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: var(--tertiary-grey);
  display: block;
}
.get-invoice-bx {
  background: var(--whiteColor);
  border: 1px solid var(--secondary-grey);
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: var(--tertiary-grey);
  max-width: 320px;
  padding: 10px;
  cursor: pointer;
}

.get-invoice-bx img {
  margin-right: 10px;
}
.order-history-modal .addon-content h5 {
  font-weight: 300;
  font-size: 40px;
  line-height: 54px;
  color: var(--primary-text-color);
  sub {
    bottom: 0;
    font-size: 24px;
  }
}
.coupon {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  p {
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    text-align: right;
    color: var(--tertiary-grey);
    margin-bottom: 0;
  }
}
.discount-bx {
  background-image: url('./../../main-app/src/assets/images/discount-wrapper.svg');
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: var(--color-white);
  width: 69.65px;
  height: 18px;
  margin-right: 10px;
}
.addon-text-wrapper {
  position: relative;
  flex: 1;
  padding: 15px 0;
}
.addon-text-wrapper h4 {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 16.0345px !important;
  line-height: 22px !important;
  color: #2a2a2a !important;
  margin-bottom: 2px !important;
}

.order-history-modal .addon-price {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.order-history-modal .addon-price p {
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  color: var(--tertiary-grey);
  margin-bottom: 0;
}
.order-history-modal .addon-price p span {
  color: var(--primary-color);
  font-weight: 500;
}
.order-history-modal .addon-price b {
  width: 38px;
  height: 15px;
  background: #f8e71c;
  margin-left: 4px;
  border-radius: 28.0855px;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  color: #2a2a2a;
  text-align: center;
}

.addon-icon img {
  width: 34px;
  height: 36px;
}

.addon-text-wrapper .addon-price p span {
  font-style: normal !important;
  font-weight: 300 !important;
  font-size: 13.1235px !important;
  line-height: 18px !important;
  color: var(--tertiary-grey) !important;
  margin-top: 3px !important;
  text-decoration: line-through !important;
}
.addon-text-wrapper .addon-price p {
  color: var(--primary-color) !important;
  font-size: 14.3165px !important;
  font-weight: 600 !important;
  text-decoration: none !important;
}
.add-cart-wrapper .addon-content:last-child {
  border-bottom: none;
}

.subscription-wrapper {
  margin-top: 40px;
}
.subscription-cart {
  background: var(--whiteColor);
  background: var(--color-white);
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 0px;
  max-width: 554px;
  margin-bottom: 34px;
  padding: 25px;
  position: relative;
}
.subscription-cart::before {
  position: absolute;
  content: url('./../../main-app/src/assets/images/page-border.png');
  top: -11px;
  height: 11px;
  width: 100%;
  left: -1px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.subscription-cart::after {
  position: absolute;
  content: url('./../../main-app/src/assets/images/page-border-bottom.png');
  bottom: -11px;
  height: 11px;
  width: 100%;
  left: -1px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.subscription-cart .add-cart-header {
  padding: 0 0 6px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 25px;
  strong {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 20px;
    text-align: right;
    color: var(--primary-text-color);
  }
}

.order-history-modal .add-cart-header h4 {
  font-size: 12px !important;
  line-height: 16px !important;
  text-align: left !important;
  color: var(--tertiary-grey) !important;
  margin-bottom: 0 !important;
}

.validiy-bx {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px auto 20px;
}
.calender-bx {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  color: var(--primary-text-color);
}
.calender-bx img {
  margin-right: 10px;
}
.control-bx i {
  margin-right: 10px;
}
.coupon-bx {
  margin-top: 10px;
  margin-bottom: 25px;
}
.coupon-bx h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: var(--primary-text-color);
  border-bottom: 1.14532px solid var(--secondary-grey);
  padding: 8px 0;
  margin-bottom: 15px;
}
.discount-coupon-bx h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  color: var(--tertiary-grey);
  margin-bottom: 0;
  text-transform: uppercase;
}
.amount-bx {
  margin-top: 20px;
}
.discount-coupon-bx h4 img {
  margin-right: 10px;
}
.discount-coupon-bx {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.coupon-child-bx {
  margin-top: 10px;
}

.coupon-child-bx p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 10.7143px;
  line-height: 13px;
  color: var(--tertiary-grey);
  margin-top: 15px;
  margin-bottom: 15px;
}
.coupon-code-bx {
  background: var(--whiteColor);
  border: 1px solid var(--secondary);
  box-sizing: border-box;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px;
}
.coupon-focus {
  border: 1px solid $primary;
}
.coupon-code-bx input {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: var(--primary-text-color);
  outline: none;
  border: none;
  width: 100%;
}
.coupon-code-bx button {
  height: 22px;
  font-size: 12px;
  font-weight: 500;
  padding: 0 12px;
}
.btn-save {
  background: var(--secondary-grey);
}
.amount-bx table {
  width: 100%;
}
.amount-bx table td {
  font-style: normal;
  font-weight: normal;
  font-size: 12.8276px;
  line-height: 17px;
  color: var(--primary-text-color);
  padding: 8px 0;
}
.amount-bx table td a {
  color: #000000;
}
.amount-bx table td:last-child {
  text-align: right;
}
.total-ammount-bx {
  border-top: 2.56552px dashed var(--secondary-grey);
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  margin: 20px -25px 0 -25px;
  padding: 20px 25px 0px 25px;
}
.amount-paid {
  font-weight: normal;
  font-size: 12.8276px;
  line-height: 17px;
  /* identical to box height */
  margin-bottom: 0;
  display: flex;
  align-items: center;
  color: var(--primary-text-color);
}
.view-details {
  font-style: normal;
  font-weight: bold;
  font-size: 16.0345px;
  line-height: 22px;
  color: var(--primary-text-color);

  a {
    font-style: normal;
    font-weight: bold;
    font-size: 11.4532px;
    line-height: 16px;
    color: #4d9bf5;
    display: block;
  }
  a:hover {
    text-decoration: none;
  }
}
.total-price {
  font-style: normal;
  font-weight: bold;
  font-size: 20.6158px;
  line-height: 28px;
  color: var(--primary-text-color);
}

.order-history-modal .recommended-wrapper {
  padding-bottom: 35%;
}
.continue-btn-bx {
  justify-content: flex-end;
  display: flex;
}
.order-history-modal .recommended-wrapper .continue-btn-bx button {
  padding: 15px 26px;
  border-radius: 50px;
  border: 1px solid $primary;
}

.order-history-modal .recommended-wrapper .addon-content {
  text-align: center;
}
.order-history-modal .recommended-wrapper .addon-content p {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: var(--tertiary-grey);
  max-width: 300px;
  margin: auto;
}

/*Order History Modal Ends*/

/*order Empty section*/
.order-empty {
  max-width: 100%;
  max-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 28px 30px;
  text-align: center;
}
.order-empty-content {
  width: 100%;
  height: 100vh;
  margin: auto;

  h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    color: var(--primary-text-color);
  }
  p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: var(--tertiary-grey);
  }
  img {
    width: 310px;
  }
  button {
    margin: 40px auto;
  }
}
/*order Empty section ends*/

/*renew subscription option modal*/
.renew-subscription-content {
  margin-top: 22px;
  position: relative;
  height: 100%;
}

.radio-custom {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-bottom: 12px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 20px;
  color: var(--primary-text-color);
}

.radio-custom input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
}

.checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  width: 13px;
  height: 13px;
  background: var(--whiteColor);
  border: 2px solid #636363;
  border-radius: 50%;
}

.radio-custom:hover input ~ .checkmark {
  background-color: var(--whiteColor);
}

.radio-custom input:checked ~ .checkmark {
  background-color: var(--whiteColor);
  border: 4px solid var(--primary);
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.radio-custom input:checked ~ .checkmark:after {
  display: block;
}
.radio-bx {
  margin-bottom: 38px;
}
.radio-bx p {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: var(--tertiary-grey);
  padding-left: 25px;
  margin-bottom: 0;
  margin-top: 15px;
  padding-right: 30px;
}

.renew-subscription-content button {
  position: absolute;
  bottom: 0;
}

// .radio-custom .checkmark:after {
//  	top: 9px;
// 	left: 9px;
// 	width: 8px;
// 	height: 8px;
// 	border-radius: 50%;
// 	background: white;
// }

/*renew subscription option modal ends*/

/*auto renewal modal*/
.auto-renewal-content {
  text-align: center;
}
.auto-renewal-content img.success-icon {
  width: 150px;
  height: 150px;
  margin: auto;
}
.auto-renewal-content h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #000000;
  margin-top: 30px;
}
.auto-renewal-content h6 {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: var(--tertiary-grey);
  max-width: 296px;
  margin: auto;
  margin-top: 4px;
}

.auto-renewal-content p {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  margin: auto;
  margin-top: 10px;
  margin-bottom: 0;
  max-width: 296px;
  color: var(--tertiary-grey);
}
.renewal-on {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 340px;
  margin: auto;
  margin-top: 52px;
  margin-bottom: 62px;
}
.renewal-on-text {
  margin-left: 17px;
}
.renewal-on-text h4 {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: var(--primary-text-color);
  margin-bottom: 4px;
}
.renewal-on-text p {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  color: var(--primary-text-color);
  margin-bottom: 0;
}

.need-help,
.need-help:hover {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  display: block;
  text-align: center;
  width: 100%;
  color: var(--tertiary-grey);
  margin-top: 35px;
  margin-bottom: 28px;
  text-decoration: none;
}
/*auto renewal modal ends*/

.shimmer-wrapper {
  //  min-height: 300px !important;
  // position: absolute !important;
}
// .shimmer-loading .ngx-shimmer {
//   min-height: 280px !important;
//   margin-top: 10px;
// }
@media all and (max-width: 320px) {
  .btn-yellow {
    font-size: 13px;
    padding: 0 15px;
  }
  .addon-card {
    padding: 10px;
  }
  .home-section-heading {
    font-size: 15px !important;
  }
}
@media only screen and (max-width: 520px) {
  .btn-border-rounded {
    width: 87px;
    height: 25px;
  }

  // add on
  .addon-header-txt h2 {
    font-size: 16px;
    line-height: 20px;
  }
  .addon-header-txt p {
    font-size: 14px;
    line-height: 18px;
  }
  .addon-header-txt {
    margin-left: 20px;
  }
  .addon-logo {
    width: 120px;
    height: 120px;
  }
  .addon-para {
    margin-top: 30px;
  }
  .addon-para h3 {
    font-size: 14px !important;
    line-height: 18px !important;
  }

  // add on ends
  .btn-yellow,
  .btn-yellow:focus {
    height: 30px;
    padding: 9px 18px;
    font-size: 10px;
    line-height: 16px;
  }

  #load::after {
    top: 75px;
  }
  .partner-owl .owl-theme .owl-nav [class*='owl-'] {
    top: 42px;
  }

  .home-section-sub-heading {
    font-size: 10px;
    margin-top: 5px;
    line-height: 12px;
  }
  .recommended-card {
    width: 140px;
    height: 210px;
  }
  .recommended-modal-content .price-text-bx {
    height: 30px;
  }
  .recommended-card p {
    font-size: 12px;
  }
  .recommended-card img {
    width: 80px;
    height: 80px;
  }
  .addons-detail-modal .recommended-wrapper {
    padding: 28px 10px;
  }

  .banner-owl .owl-prev,
  .banner-owl .owl-next {
    height: 70px;
    width: 30px;
  }
  .banner-owl .owl-prev {
    left: -10px;
  }
  .banner-owl .owl-next {
    right: -10px;
  }
  .banner-owl .owl-theme .owl-nav .owl-prev.disabled {
    left: -10px;
  }
  .banner-owl .owl-theme .owl-nav .owl-next.disabled {
    right: -10px;
  }
  /*hader buttons*/

  .section-button-wrapper {
    flex-wrap: wrap;
  }
  .btn-my-addons {
    margin-left: 0;
  }
  .section-top-header {
    margin-top: 10px;
  }
  .home-section-heading {
    margin-top: 20px !important;
  }
  .heading-bx .home-section-heading {
    margin-top: 0px !important;
  }

  /*renewal History modal*/

  .modal.fade.show .renewal-history-modal {
    left: 0;
    right: 0;
    width: initial;
  }
  .order-history-modal .addons-detail-header {
    padding: 0 20px;
  }
  .order-history-modal .addons-detail-header p {
    margin-left: 0;
  }
  /*renewal History modal ends*/

  .addons-detail-content {
    padding: 15px;
  }
  .recommended-wrapper {
    padding: 15px;
  }
  .recommended-modal-content {
    gap: 10px;
    justify-content: space-evenly;
  }

  /*tabs addon page*/
  .addons-tab-wrapper .nav-tabs .nav-item .nav-link {
    min-width: auto;
    padding: 5px 10px;
  }
  /*tabs addon page*/

  .youtube-owl .owl-carousel.owl-drag .owl-item {
    max-width: 370px;
  }
}

@media all and (min-width: 521px) and (max-width: 767px) {
  /*tabs addon page*/
  .addons-tab-wrapper .nav-tabs .nav-item .nav-link {
    min-width: auto;
    padding: 5px 10px;
  }
  /*tabs addon page*/

  .youtube-owl .owl-carousel.owl-drag .owl-item {
    max-width: 500px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .addons-tab-wrapper .nav-tabs .nav-item .nav-link {
    min-width: 150px;
    width: 150px;
  }
  .youtube-owl .owl-carousel.owl-drag .owl-item {
    max-width: 195px;
  }
  .youtube-thumbnail-wrapper {
    width: auto;
  }
}
