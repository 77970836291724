// @import "/src/theme/_variables.scss";
// @import "/src/theme/_mixin.scss";
// @import "/src/theme/_built-in-module.scss";
@import './variables';
@import './mixin';
@import './built-in-module';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

body {
  font-family: 'Open Sans', sans-serif !important;
}
// css for loader

.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: '';
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.4);
}

// css for loader

.loadsplash {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
// .loadsplash:before {
//   content: "";
//   display: block;
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background-image: url("src/assets/images/splash.gif");
//   height: 100%;
//   background-position: center;
//   background-repeat: no-repeat;
//   background-size: cover;
// }

button:focus {
  outline: none;
}

.compare-modal {
  max-width: 1000px;
}
.compare-modal .modal-content {
  background: var(--color-white);
  border: 1px solid var(--tertiary-grey);
  box-sizing: border-box;
  border-radius: 20px;
}
.flex-custom {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.modal.show .modal-dialog {
  -webkit-transform: translate(0);
  transform: translate(0);
}

/*business profile modal*/

.business-profile-modal .add-cart-wrapper .add-cart-content {
  padding: 20px 0;
  padding-bottom: 10px;
}
.business-profile-modal {
  position: fixed !important;
  margin: auto !important;
  height: 100% !important;
  right: 0 !important;

  -webkit-transition: opacity 0.3s linear, right 0.4s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.4s ease-out;
  -o-transition: opacity 0.3s linear, right 0.4s ease-out;
  transition: opacity 0.3s linear, right 0.4s ease-out;

  // -webkit-transform: translate3d(0%, 0, 0);
  // -ms-transform: translate3d(0%, 0, 0);
  // -o-transform: translate3d(0%, 0, 0);
  // transform: translate3d(0%, 0, 0);
}
.modal.fade.show .business-profile-modal {
  right: 0 !important;
  -webkit-transition: opacity 0.3s linear, right 0.4s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.4s ease-out;
  -o-transition: opacity 0.3s linear, right 0.4s ease-out;
  transition: opacity 0.3s linear, right 0.4s ease-out;
  top: -2px;

  height: 100%;

  .modal-content {
    height: 100%;
    border: none;
    border-radius: 0px;

    app-business-profile-modal {
      height: 100%;
    }
  }
}
.modal.fade .business-profile-modal {
  right: -320px;
  -webkit-transition: opacity 0.3s linear, right 0.4s ease-out;
  -moz-transition: opacity 0.3s linear, right 0.4s ease-out;
  -o-transition: opacity 0.3s linear, right 0.4s ease-out;
  transition: opacity 0.3s linear, right 0.4s ease-out;
}

.modal.fade.show .business-profile-modal {
  right: 0;
}

.business-profile-modal .modal-content {
  border-radius: 0;
  border: none;
}

// for dummy
// .modal.fade.show .renewal-history-modal {
//   right: 0 !important;
//   -webkit-transition: opacity 0.3s linear, right 0.4s ease-out;
//   -moz-transition: opacity 0.3s linear, right 0.4s ease-out;
//   -o-transition: opacity 0.3s linear, right 0.4s ease-out;
//   transition: opacity 0.3s linear, right 0.4s ease-out;
//   top: -2px;
// }

// .modal.fade .renewal-history-modal {
//   right: -320px;
//   -webkit-transition: opacity 0.3s linear, right 0.4s ease-out;
//   -moz-transition: opacity 0.3s linear, right 0.4s ease-out;
//   -o-transition: opacity 0.3s linear, right 0.4s ease-out;
//   transition: opacity 0.3s linear, right 0.4s ease-out;
// }

// .modal.fade.show .renewal-history-modal {
//   right: 0;
// }

// .renewal-history-modal .modal-content {
//   border-radius: 0;
//   border: none;
// }
/*Renewal History Modal ends*/

.slide-modal .recommended-wrapper {
  background-color: var(--color-white);
  padding: 23px 15px 15px 30px;
  border-radius: 0;
  height: 100vh;
  overflow-x: auto;
  padding-bottom: 20%;
}

// dummy end

.business-profile-modal {
  width: 428px;
}
.business-profile-modal .addons-detail-header {
  height: 100px;
}

.business-profile-modal .recommended-wrapper {
  background-color: var(--color-white);
  padding: 23px 15px 15px 30px;
  border-radius: 0;
  height: 100vh;
  overflow-x: auto;
  padding-bottom: 20%;
}
.business-profile-modal .recommended-wrapper::-webkit-scrollbar {
  width: 4px;
}

.business-profile-modal .recommended-wrapper::-webkit-scrollbar-track {
  border-radius: 6px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
}

.business-profile-modal .recommended-wrapper::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: var(--tertiary-grey);
}

.business-profile-modal .add-cart-wrapper {
  background: var(--color-white);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  max-width: 442px;
  margin-bottom: 34px;
  cursor: pointer;
}

/*business profile modal ends*/

@media all and (max-width: 600px) {
  .business-profile-modal {
    width: 100%;
  }
  .modal.fade.show .business-profile-modal {
    top: 2px;
  }
}

@media all and (min-width: 521px) and (max-width: 767px) {
}
