/* You can add global styles to this file, and also import other style files */
::ng-deep .section-wrapper {
  margin: 0 auto;
    position: relative;
    width: 100%;
    max-width: 1024px;
    padding: 0 20px;
  }
  ::ng-deep .section-container {
    border-bottom: 0;
    padding: 4em 0;
  }
  ::ng-deep .page-head {
    transition: all 0.5s;
    display: flex;
    align-items: center;
    margin-bottom: 2em;
  }
  ::ng-deep .page-head .page-title {
    justify-content: space-between;
    display: flex;
    align-items: center;
    width: 50%;
  }
  ::ng-deep .page-head-btn {
    text-align: right;
    width: 50%;
  }
  ::ng-deep .page-head .page-title > h1 {
    color: var(--tertiary-grey);
    font-size: 18px;
    margin: 0;
    font-family: Georgia;
    font-style: italic;
    text-transform: capitalize;
    background-color: var(--color-grey);
    border-radius: 4px;
    padding: 3px 11px;
    font-weight: 500;
  }
  ::ng-deep .add-btn-primary {
    display: inline-flex;
    align-items: center;
    color: var(--primary-color);
    text-transform: uppercase;
    text-decoration: none;
    padding: 6px 12px;
    border: 1px solid var(--primary-color);
    border-radius: 4px;
    margin-left: 10px;
    cursor: pointer;
  }
  ::ng-deep .add-btn-secondary{
    display: inline-flex;
    background: var(--primary-color);
    align-items: center;
    color: var(--color-white);
    text-transform: uppercase;
    text-decoration: none;
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    margin-left: 10px;
    cursor: pointer;
  }
  ::ng-deep .add-btn-primary:focus {
    outline: none;
  }
  ::ng-deep .add-icon {
    line-height: 0.8;
    vertical-align: middle;
    width: 18px;
    height: 18px;
    margin-right: 5px;
  }
  ::ng-deep .zeroth-case-title {
    margin-top: 4px;
    font-size: 20px;
    line-height: 27px;
    font-weight:600;
    color: var(--primary-text-color);
}
::ng-deep .zeroth-case-description {
  font-size: 14px;
  color: var(--primary-text-color);
  margin:0 8em;
}


::ng-deep .zeroth-case-container {
  padding: 6em;
  background: var(--color-grey);
        border: 1px dashed var(--secondary-grey);
        box-sizing: border-box;
        border-radius: 20px;
    }
 ::-webkit-scrollbar-track{
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.1);
      background-color: var(--color-grey);
      border-radius: 10px;
  }

  ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
      background-color: var(--color-grey);
  }

  ::-webkit-scrollbar-thumb{
      border-radius: 10px;
      background-color: var(--color-white);
      background-image: -webkit-gradient(linear, 40% 0%, 75% 84%, from(#ccc), to(#ccc), color-stop(.6,#ccc));
  }
  .icon-btn {
    height: 29px !important;
    width: 29px !important;
    border-radius: 15px;
    background-color: #e9e9e9;
    color: var(--tertiary-grey);
    display: flex !important;
    flex-direction: row;
    font-size: 20px !important;
    justify-content: center;
    align-items: center;  
    
  }
  
