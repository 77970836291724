@import "../theme/_built-in-module.scss";
// ============================Border================
@mixin border($direction, $width, $style, $color) {
  border-#{$direction}: $width $style $color;
}
@mixin border($width, $style, $color) {
  border: $width $style $color;
}

// ============================Box-shadow================

@mixin boxshadow($depth: 1) {
  @if $depth==0 {
    // For Slider Working
    box-shadow: none;

    // Original
    // --box-shadow: none;
  }
  @if $depth==1 {
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.15);
  } @else if $depth==2 {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.14);
  } @else if $depth==3 {
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.3);
  }
  // transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
}

// ============================Border-radius================
@mixin border-radius($value: 9.55852px) {
  -webkit-border-radius: $value;
  -moz-border-radius: $value;
  border-radius: $value;
  // keeps background from busting out of border
  -webkit-background-clip: padding-box;
  -moz-background-clip: padding;
  background-clip: padding-box;
}

// ============================Font-size================

@mixin font-size($value: 14) {
  @if $value==10 {
    font-size: (0.0625 * $value) + em;
    line-height: map-get($line-height, "extra-small-font-10");
  }
  @if $value==12 {
    font-size: (0.0625 * $value) + em;
    line-height: map-get($line-height, "small-font-12");
  }
  @if $value==13 {
    font-size: (0.0625 * $value) + em;
    line-height: map-get($line-height, "default-medium-font");
  }
  @if $value==14 {
    font-size: (0.0625 * $value) + em;
    line-height: map-get($line-height, "default-font");
  }
  @if $value==16 {
    font-size: (0.0625 * $value) + em;
    line-height: map-get($line-height, "root-font");
  }
  @if $value==18 {
    font-size: (0.0625 * $value) + em;
    line-height: map-get($line-height, "root-font");
  }
  @if $value==20 {
    font-size: (0.0625 * $value) + em;
    line-height: map-get($line-height, "root-font");
  }
  @if $value==24 {
    font-size: (0.0625 * $value) + em;
    line-height: map-get($line-height, "root-font");
  }
  @if $value==26 {
    font-size: (0.0625 * $value) + em;
    line-height: map-get($line-height, "root-font");
  }
}

// ============================Truncate-text================
@mixin truncateText($overflow: ellipsis) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: $overflow;
}

// ============================Opacity================
@mixin opacity($value: 0.5) {
  opacity: $value;
}
// ============================Spacing================

@mixin margin($val1, $val2, $val3, $val4) {
  margin-top: $val1;
  margin-right: $val2;
  margin-bottom: $val3;
   margin-left: $val4;
}

@mixin padding($val1, $val2, $val3, $val4) {
  padding-top: $val1;
  padding-right: $val2;
  padding-bottom: $val3;
   padding-left: $val4;
}

// ============================Gradient================

$colorList: $lightgray 0%, $darkgray 100%;
$colorListYellow: $lightyellow 0.96%, $darkyellow 99.04%;
$colorListBlue: $lightyellow 1.47%, $darkyBlue 98.53%;

@mixin linear-gradient($direction, $colorList) {
  background: -webkit-linear-gradient($direction, $colorList);
  background: -moz-linear-gradient($direction, $colorList);
  background: -o-linear-gradient($direction, $colorList);
  background: linear-gradient($direction, $colorList);
}



// ============================Position================

@mixin position(
  $position,
  $top: null,
  $right: null,
  $bottom: null,
  $left: null
) {
  position: $position;
  top: $top;
  right: $right;
  bottom: $bottom;
  left: $left;
}
