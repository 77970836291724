/* You can add global styles to this file, and also import other style files */

$colors: (
  'gray': var(--tertiary-grey),
  'white': var(--color-white),
  'dark': var(--primary-text-color),
  'primary': var(--primary-color),
  'warn': rgb(227, 149, 149),
);
$fontWeight: 400, 500, 600;

.add-in-clinic-dialog {
  margin-top: 33px;
  .mat-dialog-container {
    padding: unset !important;
    overflow: unset !important;
    box-shadow: unset !important;
    background: transparent !important;
  }
}
.cdk-overlay-backdrop.dark-backdrop {
  background: rgba(0, 0, 0, 0.8) !important;
}
@each $weight in $fontWeight {
  @each $name, $color in $colors {
    @for $i from 8 through 36 {
      [title-#{$i}-#{$name}-#{$weight}] {
        font-size: $i + px !important;
        color: $color !important;
        font-weight: $weight !important;
        line-height: $i + px !important;
        &.mat-icon {
          height: $i + px !important;
          width: $i + px !important;
          min-width: $i + px !important;
          min-height: $i + px !important;
          svg {
            fill: $color !important;
          }
        }
      }
    }
  }
}
.snackbar-success {
  background-color: var(--primary-color) !important;
  color: var(--color-white) !important;
  margin-bottom: 70px !important;
  z-index: 12;
}
.snackbar-error {
  background-color: #b14040 !important;
  color: var(--color-white) !important;
  margin-bottom: 70px !important;
  z-index: 12;
}
//akhil global css for this individual proj
::ng-deep .section-wrapper {
  margin: 0 auto;
  position: relative;
  width: 100%;
  max-width: 1024px;
  padding: 0 20px;
}
::ng-deep .section-container {
  border-bottom: 0;
  padding: 4em 0;
}
::ng-deep .page-head {
  transition: all 0.5s;
  display: flex;
  align-items: center;
  margin-bottom: 2em;
}
::ng-deep .page-head .page-title {
  justify-content: space-between;
  display: flex;
  align-items: center;
  width: 50%;
}
::ng-deep .page-head-btn {
  text-align: right;
  width: 50%;
}
::ng-deep .page-head .page-title > h1 {
  color: var(--tertiary-grey);
  font-size: 18px;
  margin: 0;
  font-family: Georgia;
  font-style: italic;
  text-transform: capitalize;
  background-color: var(--color-grey);
  border-radius: 4px;
  padding: 3px 11px;
  font-weight: 500;
}
::ng-deep .add-btn-primary {
  display: inline-flex;
  align-items: center;
  color: var(--primary-color);
  text-transform: uppercase;
  text-decoration: none;
  padding: 6px 12px;
  border: 1px solid var(--primary-color);
  border-radius: 4px;
  margin-left: 10px;
  cursor: pointer;
}
::ng-deep .add-btn-secondary {
  display: inline-flex;
  background: var(--primary-color);
  align-items: center;
  color: var(--color-white);
  text-transform: uppercase;
  text-decoration: none;
  padding: 6px 12px;
  border: none;
  border-radius: 4px;
  margin-left: 10px;
  cursor: pointer;
}
::ng-deep .add-btn-primary:focus {
  outline: none;
}
::ng-deep .add-icon {
  line-height: 0.8;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  margin-right: 5px;
}
